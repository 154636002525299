import { useEffect, useState } from "react";
import Modal from "../../components/modal";
import { permissions } from "../../utils/baseUrl";
import axios from "axios";
import CustomTable from '../../components/table/index';
import Toast from "../../components/toast";

export default function Users() {
    const [toast , setToast]= useState(false);
    const [openModal , setOpenModal] = useState(false);
    const [users , setUsers] = useState([]);
    const [rowData , setRowData] = useState({})
    const [selectAll, setSelectAll] = useState(false);
    const [editModal , setEditModal] = useState(false);
    const userData = localStorage.getItem("moreenglishlogin") ? JSON.parse(localStorage.getItem("moreenglishlogin")) : [];
    const [adminData , setAdminData] = useState({
        email:"",
        password:"",
        permissions:"",
        type:"",
        user_name:"",
    })

    const columns = [
        {
            id:"user_id",
            dataIndex:"user_id",
            title:"Id",
        },
        {
            id:"user_name",
            dataIndex:"user_name",
            title:"User Name",
        },
        {
            id:"email",
            dataIndex:"email",
            title:"Email",
        },
        {
            id:"password",
            dataIndex:"password",
            title:"Password",
        },
        {
            id:"type",
            dataIndex:"type",
            title:"Type",
            render:(text , row) => <p>{row?.type=="main_admin" ? "Admin" : "Assistant"}</p>
        },
        {
            id:"permissions",
            dataIndex:"permissions",
            title:"Permissions",
            render:(text , row) => {
                const permissionsIDS = row?.permissions?.split("//CAMP//") || [];
                const filteredPermision = permissions.filter(item => permissionsIDS?.some(perm => item?.id == perm))
                console.log(filteredPermision);
                return (
                    <ul>
                {filteredPermision.map(perm => <li key={perm?.id}>{perm?.label}</li>)}
            </ul>
                )
            }
        },
        {
            title:"Actions",
            render:(text , row) => <div className="d-flex gap-3">
                <button className="btn btn-primary" onClick={() => {
                    console.log(row);
                    setEditModal(true);
                    setRowData(row)
                }}>Edit</button>

                <button className="btn btn-danger" onClick={() => handleBanUser(row?.user_id)}>Ban</button>
            </div>
        }
    ]

    function handleEditUser(e) {
        e.preventDefault();
        const data_send = {
            ...rowData
        }
        axios.post("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/authentication/edit_user_data.php",data_send)
        .then(res => {
            if(res?.data?.status == "success") {
                setToast({type:"success",message:res?.data?.message});
                handleGetAllUsers();
                setRowData({});
                setEditModal(false);
            }else {
                setToast({type:"error",message:res?.data?.message || "هناك خطأ ما"});
            }
        }).catch(e => console.log(e))
        .finally(() => setEditModal(false))
    }

   function handleBanUser(id) {
     if(rowData?.paused == 1) {
        setToast({type:"error",message:"الطالب محظور بالفعل"});
        return;
     }

     const data_send = {
        user_id : id,
        pused : 1,
     }
     axios.post("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/authentication/pan_user.php",data_send)
     .then(res=>{
        console.log(res)
        if(res?.data?.status == "success") {
            setToast({type:"success",message:res?.data?.message});
            handleGetAllUsers();
        }else {
            setToast({type:"error",message:res?.data?.message  || "هناك خطأ ما"})
        }
     }).catch(e => console.log(e))
   }

    function handleGetAllUsers() {
       const data_send = {
        user_id : userData?.user_id,
        type: userData?.type
       }

       axios.post("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/authentication/select_all_users.php",data_send)
       .then(res => {
        if(res?.data?.status =="success") {
          setUsers(res?.data?.message);
        }
       }).catch(e => console.log(e))
    }
 
    useEffect(() => {
        handleGetAllUsers();
    } , [])

    function handleSubmit(e) {
        e.preventDefault();
        
        const data_send = {
            ...adminData,
            type : userData?.type
        }

        axios.post("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/authentication/add_user.php",data_send )
        .then(res =>{
            if(res?.data?.status =="success") {
                setToast({type:"success",message:res?.data?.message})
                handleGetAllUsers();
                setOpenModal(false);
                setAdminData({
                    email:"",
        password:"",
        permissions:"",
        type:"",
        user_name:"",
                })
            }
        }).catch(e => console.log(e))
        .finally(() => setOpenModal(false));
    }
    
    function handlePermissionChange(id, isChecked, forEdit = false) {
        const permissionsString = forEdit ? rowData.permissions : adminData.permissions;
        const selectedPermissions = permissionsString ? permissionsString.split("//CAMP//") : [];
    
        const permId = String(id); // Convert `id` to string for consistency
    
        if (isChecked) {
            // Add the permission if it’s not already included
            if (!selectedPermissions.includes(permId)) {
                selectedPermissions.push(permId);
            }
        } else {
            // Remove the permission if it’s unchecked
            const index = selectedPermissions.indexOf(permId);
            if (index !== -1) selectedPermissions.splice(index, 1);
        }
    
        const updatedPermissions = selectedPermissions.join("//CAMP//");
    
        if (forEdit) {
            setRowData({
                ...rowData,
                permissions: updatedPermissions
            });
        } else {
            setAdminData({
                ...adminData,
                permissions: updatedPermissions
            });
        }
    }
    
    function handleCheckUser() {
        const data_send = {
            user_id : userData?.user_id,
        }
        axios.post("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/authentication/check_user.php",data_send)
        .then(res => console.log(res))
    }

    useEffect(() => {
        handleCheckUser()
    } , [])


    function handleSelectAllPermissions(e, forEdit = false) {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        
        const allPermissions = isChecked ? permissions.map(perm => (perm.id)).join("//CAMP//") : "";

        if (forEdit) {
            setRowData({
                ...rowData,
                permissions: allPermissions
            });
        } else {
            setAdminData({
                ...adminData,
                permissions: allPermissions
            });
        }
    }

    
  return (
    <div>
        <div className="tablePageHeader">
        <h1 className="pageTitle">Admins</h1>
        <button
          className="btn btn-success"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Add Admin
        </button>
      </div>
  
     <CustomTable columns={columns} dataSource={users}/>

     <Modal visible={editModal} close={setEditModal} title="Edit Admin">
        <form onSubmit={handleEditUser}>
        <div className="form-group">
                <label className="form-label">User Name</label>
                <input type="text" placeholder="user name" value={rowData?.user_name} onChange={(e) => setRowData({...rowData , user_name:e.target.value})} />
            </div>
    
            <div className="form-group">
                <label className="form-label">Email</label>
                <input type="email" placeholder="email" value={rowData?.email} onChange={(e) => setRowData({...rowData , email:e.target.value})} />
            </div>

            <div className="form-group">
                <label className="form-label">password</label>
                <input type="password" placeholder="password" value={rowData?.password} onChange={(e) => setRowData({...rowData , password:e.target.value})} />
            </div>

           <h2 >Permissions</h2>
           
           {permissions.map(perm => (
    <div key={perm.id} style={{ display: "flex", gap: "4px", alignItems: "center" }}>
        <label className="form-label">{perm.label}</label>
        <input
            type="checkbox"
            checked={rowData.permissions?.split("//CAMP//").includes(String(perm.id))}
            onChange={(e) => handlePermissionChange(perm.id, e.target.checked, true)}
        />
    </div>
))}

           <button className="btn btn-primary">Edit</button>
        </form>
      </Modal>

      <Modal visible={openModal} close={setOpenModal} title="Add Admin">
        <form onSubmit={handleSubmit}>
        <div className="form-group">
                <label className="form-label">User Name</label>
                <input type="text" placeholder="user name" onChange={(e) => setAdminData({...adminData , user_name:e.target.value})} />
            </div>
    
            <div className="form-group">
                <label className="form-label">Email</label>
                <input type="email" placeholder="email" onChange={(e) => setAdminData({...adminData , email:e.target.value})} />
            </div>

            <div className="form-group">
                <label className="form-label">password</label>
                <input type="password" placeholder="password"onChange={(e) => setAdminData({...adminData , password:e.target.value})} />
            </div>

           <h2 >Permissions</h2>
           <div style={{display:"flex",gap:"5px"}}>
            <label className="form-label" style={{margin:"auto 0px"}}>Permission All</label>
            <input checked={selectAll} type="checkbox" className="form-input" onChange={(e) => handleSelectAllPermissions(e)}/>
           </div>

              {permissions.map(perm => <div style={{display:"flex",gap:"4px",alignItems:"center"}}>
                <label className="form-label"  style={{margin:"auto 0px"}} key={perm?.id}>{perm?.label}</label>
                <input onChange={(e) => handlePermissionChange(perm.id, e.target.checked)} className="form-input"type="checkbox"/>
              </div>)}
           {/* </div> */}

           <button className="btn btn-primary">Add</button>
        </form>
      </Modal>

      {toast && (
          <Toast
            message={toast?.message}
            type={toast?.type}
            onClose={() => setToast(false)}
          />
        )}
    </div>
  )
}
