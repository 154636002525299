import { useLocation } from "react-router-dom";
import "./App.css";
import DefaultLayout from "./Layouts/defaultLayout";
import RoutesComponent from "./components/Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import DropMenu from "./components/dropmenu";
import axios from "axios";
import { useEffect } from "react";

function App() { 
  const location = useLocation();
   const userData = localStorage.getItem("moreenglishlogin") ? JSON.parse(localStorage.getItem("moreenglishlogin")) : null;
  function handleCheckUser() {
    const data_send = {
      user_id : userData?.user_id
    }
    axios.post("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/authentication/check_user.php",data_send)
    .then(res => {
      if(res?.data?.status == "success") {
         localStorage.setItem("moreenglishlogin",JSON.stringify(res?.data?.message))
      }
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    setTimeout(()=>{
      handleCheckUser();
    } ,300)
  } , [location?.pathname])

  return (
    <>
      {" "}
      {!localStorage.getItem("moreenglishlogin") ? (
        <RoutesComponent />
      ) : (
        <DefaultLayout>
          <RoutesComponent />
          {/* <DropMenu /> */}
        </DefaultLayout>
      )}
    </>
  );
}

export default App;
