import React, { useEffect, useState } from 'react'
import CustomTable from "../../../components/table";
import DropMenu from "../../../components/dropmenu";
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Modal from '../../modal';

export const DeplomaLevels = () => {
    const navigate = useNavigate();

    const{subject}=useParams()
    const userPermissions = localStorage.getItem("moreenglishlogin") ? JSON.parse(localStorage.getItem("moreenglishlogin"))?.permissions : null;
    const [spliitedUserPermission , setSplittedUserPermissions] = useState([]);
    const[LevelsData , setLevelsData]=useState([])
    const[AddLevel , setAddLevel]=useState(false)
    const[OpenDeleteModal , setOpenDeleteModal]=useState(false)
    const[OpenEditModal , setOpenEditModal]=useState(false)
    const[NewLevelData , setNewLevelData]=useState(
      {
        name:""
      }
    )

    useEffect(() =>{
      const spliitedPermissions = userPermissions.split("//CAMP//");
      setSplittedUserPermissions(spliitedPermissions);
    } , [userPermissions])
  

    const getSubjectLevels = async()=>{
      let data_to_send={
        lec_sub_id:subject,
      }

      axios
      .post(
        "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/select_level.php",
        JSON.stringify(data_to_send))
        .then(res=>{
          if(res.data.status = "success"){
            setLevelsData(res.data)
          } else if(res.data.status = "error"){
            toast.error(res.data.message)
            
          }
        })
        .catch(e=>console.log(e))
    }


    useEffect(()=>{
      getSubjectLevels()
    },[])


    const handelAddLevel = async()=>{
      let data_to_send={
        lec_sub_id:subject,
        name:NewLevelData.name
      }

      axios
      .post(
        "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/insert_level.php",
        JSON.stringify(data_to_send))
        .then(res=>{
          if(res.data.status = "success"){
            toast.success(res.data.message)
            setAddLevel(false)
            getSubjectLevels()
          } else if(res.data.status = "error"){
            toast.error(res.data.message)
            
          }
        })
        .catch(e=>console.log(e))
    }

   
    const handelDeleteLevel = async()=>{
      let data_to_send={
        level_id:OpenDeleteModal.level_id
      }

       console.log(data_to_send);
      

      axios
      .post(
        "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/delete_level.php",
        JSON.stringify(data_to_send))
        .then(res=>{
          if(res.data.status = "success"){
            toast.success(res.data.message)
            setOpenDeleteModal(false)
            getSubjectLevels()
          } else if(res.data.status = "error"){
            toast.error(res.data.message)
            
          }
        })
        .catch(e=>console.log(e))
    }


    const columns = [
        {
          key: "name",
          title: "level Name",
          dataIndex: "name",
          search: true,
        },
        {
          key: "actions",
          title: "Actions",
          dataIndex: "actions",
          render: (text, row) => (
            <div className="actions-btns">
             
              {/* 
              <div
                onClick={() => setOpenShowHideModal(row)}
                className={
                  row?.hidden
                    ? "showhide-btn c-pointer text-success"
                    : "showhide-btn c-pointer text-danger"
                }
              >
                <div className="tooltip">{row?.hidden ? "Show" : "Hide"}</div>
                {row?.hidden ? closedEye : openedEye}
              </div> */}
              
              <DropMenu child={"Actions"}>
              <div className="actions-btn">
                  {spliitedUserPermission?.includes("42") && <div
                    className="open-btn c-pointer text-primary"
                    onClick={() => navigate(`${row?.level_id}/Lectures`)}
                  >
                    <div className="btn btn-primary">Lectures</div>
                    
                  </div>}
                  
                </div>

               {spliitedUserPermission?.includes("23") &&  <div
                className="delete-btn c-pointer text-danger"
                style={{margin:"0px 10px"}}
                onClick={() => setOpenDeleteModal(row)}
              >
                <div className="btn btn-danger">Delete</div>
              </div>}

             {spliitedUserPermission?.includes("22") &&  <div
                className="open-btn c-pointer text-primary"
                onClick={() => setOpenEditModal(row)}
              >
                <div className="btn btn-warning">Edit</div>
                
              </div>}
                
              </DropMenu>
              {/* <div
                className="open-btn c-pointer text-danger"
                onClick={() => navigate(`${row?.lec_id}/AbsentStudents`)}
              >
                <div className="tooltip">Absent Students</div>
                {AbsentStudents}
              </div> */}
              
            </div>
          ),
        },
      ];
  return (
    <div className="lectures">
    <div className="tablePageHeader">
      <h1 className="pageTitle">Deplouma levels</h1>
      {spliitedUserPermission?.includes("21") && <button
        className="btn btn-success"
        onClick={() => {
          setAddLevel(true);
        }}
      >
        Add Deplouma level
      </button>}
    </div>
    <CustomTable dataSource={LevelsData} columns={columns} />
    <Modal
      close={setAddLevel}
      footer={false}
      title={"Add Level"}
      visible={AddLevel}
    >
       <div className="form-group">
            <label htmlFor="pdfName" className="animated-label">
            Level Name
            </label>
            <input
              type="text"
              id="pdfName"
              placeholder="Level Name"
              className="animated-input"
              required
              onChange={(e)=>{
                setNewLevelData(
                  {
                    ...NewLevelData,
                    name:e.target.value
                  }
                )
              }}
            />
          </div>
          <button onClick={handelAddLevel}  className="btn btn-success" style={{margin:"10px 0"}}>Add</button>

    </Modal>



    <Modal
      close={() => setOpenDeleteModal(false)}
      footer={false}
      title={"Delete level"}
      visible={OpenDeleteModal}
    >
      <div className="delete-warning">
        <h3>Are you sure you want to delete this level?</h3>
        <p className="warning-message">
          This action cannot be undone. Please confirm that you want to delete
          the following level:
        </p>
        <div className="lecture-details">
          <strong>Title:</strong> {OpenDeleteModal?.name}
        </div>
        
        
        <div className="rowEndDiv">
        <div
                className="delete-btn c-pointer text-danger"
                style={{margin:"0px 10px"}}
                onClick={handelDeleteLevel}
              >
                <div className="btn btn-danger">Delete</div>
              </div>
          
        </div>
       
      </div>
    </Modal>
    <Toaster
  position="top-center"
  reverseOrder={false}
/>
  </div>
  )
}
