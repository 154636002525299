export const baseUrl = "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/"
export const thirdUrl = "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/"
export const secondUrl = "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/"

export const permissions = [
 {id : 1 , label : "Add video" },
 {id:2,label:"Edit video"},
 {id:3,label:"Delete video"},
{id :4,label:"Hide video"},
{id:5,label:"Add pdf"},
{id :6,label:"Delete pdf"},
{id:7,label:"create card"},
{id:8,label:"Add course"},
{id:9,label:"Edit course"},
{id:10,label:"Delete course"},
{id:39 , label:"Add course_content"},
{id:44 ,label:"Add Content Sheet Pdf"},
{id:45 ,label:"Edit Content Sheet Pdf"},
{id:46 ,label:"Delete Content Sheet Pdf"},
{id:11,label:"content management"},
{id:12,label:"Add lecture"},
{id:13,label:"Delete lecture"},
{id:40, label:"Show video lecture"},
{id:14,label:"Add lecture_video"},
{id:15,label:"Edit lecture_video"},
{id:16,label:"Delete lecture_video"},
{id:17,label:"Add lecture_pdf"},
{id:18,label:"delete lecture_pdf"},
{id:19,label:"add diploma"},
{id:20,label:"delete diploma"},
{id:41,label:"show levels"},
{id:21,label:"Add diploma_level"},
{id:22,label:"Edit diploma_level"},
{id:23,label:"delete diploma_level"},
{id:42 , label:"show Diploma lectures"},
{id:31,label:"add student"},
{id:32,label:"edit student"},
{id:33,label:"delete student"},
{id:34,label:"reset device_student"},
{id:43,label:"show student subscription"},
{id:35,label:"add student_subscription"},

{id:36,label:"edit student_subscription"},
{id:37,label:"delete student_subscription"},
{id:38,label:"ban student"},
{id:47,label:"show subscription page"},
{id:48,label:"show Students page"},
{id:49,label:"show Diploma page"},
{id:50,label:"show Courses page"},
{id:51,label:"show Create Cards page"},
{id:52,label:"show Videos page"},
]