import React, { useEffect, useState } from "react";
import CustomTable from "../../components/table";
import "./style.css";
import { baseUrl, secondUrl } from "../../utils/baseUrl";
import Toast from "../../components/toast";
import ChangeGroup from "../../components/students/changegroup";
import SelectComponent from "../../components/selectBox";
import Modal from "../../components/modal";
import axios from "axios";
import { FcDeleteColumn, FcDeleteDatabase } from "react-icons/fc";

// ConfirmModal Component
function ConfirmModal({ visible, onClose, onConfirm, message }) {
  if (!visible) return null;

  return (
    <Modal
      close={onClose}
      footer={false}
      title={"Confirm Action"}
      visible={visible}
    >
      <div className="confirm-modal">
        <p>{message}</p>
        <div className="confirm-modal-buttons">
          <button className="btn btn-danger" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

function Subscription() {
  const userPermissions = localStorage.getItem("moreenglishlogin") ? JSON.parse(localStorage.getItem("moreenglishlogin"))?.permissions : null;
  const [spliitedUserPermission , setSplittedUserPermissions] = useState([]);
  const [toast, setToast] = useState(false);
  const [openChangeGroup, setOpenChangeGroup] = useState(false);
  const [students, setStudents] = useState(null);
  const [openAddSubCard, setOpenAddSubCard] = useState(false);
  const [EditSubscreption, setEditSubscreption] = useState(false);
  const [DeletModal, setDeletModal] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [RowData, setRowData] = useState(null);

  const [years, setYears] = useState([]);
  const [groups, setGroups] = useState([]);
  const [Subjects, setSubjects] = useState([]);

  const [packages, setPackages] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({
    centerType: "",
    gen_id: "",
    group_id: "",
    package_id: "",
  });
  const [confirmAction, setConfirmAction] = useState({
    visible: false,
    message: "",
    onConfirm: null,
  }); // State for confirmation modal

  useEffect(() =>{
    const spliitedPermissions = userPermissions.split("//CAMP//");
    setSplittedUserPermissions(spliitedPermissions);
  } , [userPermissions ])

  useEffect(() => {
    getStudents();
    getYears();
  }, []);

  const getStudents = async () => {
    try {
      const response = await fetch(
        "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/select_sub_student.php"
      );
      const data = await response.json();
      setStudents(data);
    } catch (err) {
      setStudents([]);
    }
  };

  const getYears = async () => {
    try {
      const yearsData = await fetch(
        baseUrl + "subscriptions/select_genrations.php"
      );
      const data = await yearsData.json();
      setYears(data);
    } catch (err) {
      setYears([]);
    }
  };

  useEffect(() => {
    if (subscriptionData.gen_id) {
      const selectedYear = years.find(
        (year) => year.gen_id === subscriptionData.gen_id
      );
      setGroups(selectedYear?.groups || []);
    }
  }, [subscriptionData.gen_id]);

  useEffect(() => {
    if (subscriptionData.group_id) {
      const selectedGroup = groups.find(
        (group) => group.group_id === subscriptionData.group_id
      );
      setPackages(selectedGroup?.packages || []);
    }
  }, [subscriptionData.group_id]);

  const handleCreateSubscription = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(secondUrl + "add_student_sub.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...subscriptionData,
          student_id: selectedStudent?.student_id,
        }),
      });
      const result = await response.text();
      setToast({ message: result, type: "success" });
      setOpenAddSubCard(null);
      setSelectedStudent(null); // Reset the selected student after creation
    } catch (error) {
      setToast({ message: "Error creating subscription", type: "error" });
    }
  };

  // Function to change subscription status (0 or 1)
  const changeSubscriptionStatus = async (student_id, package_id, status) => {
    try {
      const response = await fetch(secondUrl + "select_sub_student.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ student_id, package_id, status }),
      });
      const result = await response.text();
      setToast({ message: result, type: "success" });
    } catch (error) {
      setToast({
        message: "Error changing subscription status",
        type: "error",
      });
    }
  };

  // Trigger the confirm modal for the activation/deactivation
  const triggerConfirmModal = (message, onConfirm) => {
    setConfirmAction({
      visible: true,
      message,
      onConfirm,
    });
  };




  const handelUpdateSubscreption = async ()=>{
    let dataSend ={
      student_subject_id:RowData?.student_subject_id,
      subscription_end:RowData?.subscription_end
    }

    console.log(dataSend);

    await axios.post(baseUrl + "edit_sub_student.php",JSON.stringify(dataSend))
    .then(res=>{
      if(res.data.status = "success"){
        setToast({type:"success", show: true, message:res.data.message });
        getStudents()
        setEditSubscreption(false)
        
      }else if(res.data.status = "error"){
        setToast({type:"error", show: true, message: res.data.message });
      }
    }).finally(()=>{
      setToast(false)
    }).catch(e=>console.log(e))
    
  }


  const DeleteStudentSubscreption = async()=>{
    let dataSend ={
      student_subject_id:RowData.student_subject_id
    }

    console.log(dataSend);

    await axios.post(baseUrl + "delete_sub_student.php",JSON.stringify(dataSend))
    .then(res=>{
      if(res.data.status = "success"){
        setToast({type:"success", show: true, message:res.data.message });
        getStudents()
        setDeletModal(false)
        
      }else if(res.data.status = "error"){
        setToast({type:"error", show: true, message: res.data.message });
      }
    }).finally(()=>{
      setToast(false)
    }).catch(e=>console.log(e))
  }


  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "student_name",
      search: true,
    },
    {
      key: "student_phone",
      title: "Student Phone",
      dataIndex: "student_phone",
      render: (e, row) => {
        return <span>{row?.student_phone}</span>;
      },
    },
    {
      key: "student_status",
      title: "student_status",
      dataIndex: "student_status",
      render: (e, row) => {
        return <span>{row?.student_status}</span>;
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "student_email",
      render: (e, row) => {
        return <span>{row?.student_email}</span>;
      },
    },
    {
      key: "sub_type",
      title: "sub_type",
      dataIndex: "sub_type",
      render: (e, row) => {
        return <span>{row?.sub_type}</span>;
      },
    },
    {
      key: "subscription end",
      title: "subscription end",
      dataIndex: "subscription_end",
      render: (e, row) => {
        return <span>{row?.subscription_end}</span>;
      },
    },

    {
      key: "Actions",
      title: "Actions",
      dataIndex: "reset_count",
      render: (e, row) => {
        return (
          <div className="rowDiv">
            {spliitedUserPermission?.includes("38") &&  <button
              className={`btn ${
                !parseInt(row?.pused) ? "btn-danger" : "btn-success"
              }`}
              onClick={async () => {
                // alert(row?.paused)
                try {
                  const response = await fetch(
                    "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/update_ban_student.php",
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        pused: parseInt(row?.pused) ? 0 : 1,
                        student_subject_id: row?.student_subject_id,
                      }),
                    }
                  );
                  const x = await response?.text();
                  getStudents();
                  setToast({ type: "dark", message: x });
                } catch (err) {}
              }}
            >
              {parseInt(row?.pused) ? "Un Ban" : " Ban"}
            </button>}
            {spliitedUserPermission?.includes("36") && <button
              className="btn btn-warning"
              style={{ color: "white", margin: "0px 10px" }}
              onClick={() => {
                setEditSubscreption(true);
                setRowData(row);
              }}
            >
              Edit
            </button>}
            {spliitedUserPermission?.includes("37") &&  <button
              className="btn btn-danger"
              style={{ color: "white", margin: "0px 10px" }}
              onClick={() => {
                setDeletModal(true);
                setRowData(row);
              }}
            >
              delete
            </button>}
          </div>
        );
      },
    },
  ];

  return (
    <div className="students">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Students</h1>
      </div>
      <CustomTable dataSource={students} columns={columns} />
      <ChangeGroup
        userData={openChangeGroup}
        openModal={openChangeGroup}
        setOpenModal={setOpenChangeGroup}
      />

      {/* Render Add Subscription Form if a student is selected */}
     

      {/* Confirm Modal */}
      <ConfirmModal
        visible={confirmAction.visible}
        message={confirmAction.message}
        onClose={() => setConfirmAction({ ...confirmAction, visible: false })}
        onConfirm={() => {
          confirmAction.onConfirm();
          setConfirmAction({ ...confirmAction, visible: false });
        }}
      />

      <Modal
        close={setEditSubscreption}
        footer={false}
        title={"Edit Subscription"}
        visible={EditSubscreption}
      >
        <div className="subscription-form-section">
          <div className="form-group">
            <label htmlFor="centerType">Subscription end date</label>
            <input
              type="date"
              defaultValue={RowData?.subscription_end}
              onChange={(e) => {
                setRowData({
                  ...RowData,
                  subscription_end: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <button className="btn btn-success" style={{ margin: "10px 0" }} onClick={handelUpdateSubscreption}>
          edit
        </button>
      </Modal>

      <Modal
        close={() => setDeletModal(false)}
        footer={false}
        title={"Delete Video"}
        visible={DeletModal}
      >
        <div className="delete-warning">
          <h3>Are you sure you want to delete this subscreption?</h3>
          <p className="warning-message">
            This action cannot be undone. Please confirm that you want to delete
            the following subscreption:
          </p>
          <div className="video-details">
            <strong>student Name:</strong> {RowData?.student_name}
          </div>
          <div className="video-details">
            <strong>subject Name:</strong> {RowData?.sub_type}
          </div>
          <div className="rowEndDiv">
            <button
              type="button"
              className="btn btn-danger"
              onClick={DeleteStudentSubscreption}
            >
              Delete
            </button>
          </div>
          {toast && (
            <Toast
              message={"An error occurred. Please try again."}
              type={"error"}
              onClose={() => setToast(false)}
            />
          )}
        </div>
      </Modal>

      {toast && (
        <Toast
          message={toast?.message}
          type={toast?.type}
          onClose={() => setToast(false)}
        />
      )}
    </div>
  );
}

export default Subscription;
