import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  json,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AddVideo from "../../../components/days/videos/add";
import DeleteVideo from "../../../components/days/videos/delete";
import EditVideo from "../../../components/days/videos/edit";
import PdfOpen from "../../../components/days/videos/pdf";
import ShowHideVideo from "../../../components/days/videos/showHide";
import DropMenu from "../../../components/dropmenu";
import CustomTable from "../../../components/table";
import "./style.css";
import { baseUrl, secondUrl } from "../../../utils/baseUrl";
import Toast from "../../../components/toast";

function CategoryVideos() {
  const [toast , setToast] = useState(false);
  const [videos, setVideos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openShowHideModal, setOpenShowHideModal] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const { lecture} = useParams();
  const location = useLocation();
  const [getNew, setGetNew] = useState(null);
  const [videoData] = useSearchParams();
  const userPermissions = localStorage.getItem("moreenglishlogin") ? JSON.parse(localStorage.getItem("moreenglishlogin"))?.permissions : null;
  const [spliitedUserPermission , setSplittedUserPermissions] = useState([]);

  useEffect(() =>{
    const spliitedPermissions = userPermissions.split("//CAMP//");
    setSplittedUserPermissions(spliitedPermissions);
  } , [userPermissions ])


 const getLecVideos = async () =>{
  let data_to_send={
    lec_id:lecture
  }

  axios.post(baseUrl + 'select_videos_lec.php', JSON.stringify(data_to_send))
  .then(res=>{
    if(res.data.status = "success"){
      setVideos([...res.data.message])
    } else if(res.data.status = "error"){
      alert(res.data.message);
      
    }
  }).catch(e=>console.log(e))
 }


 useEffect(()=>{
  getLecVideos()
 },[])

  const columns = [
    {
      key: "name",
      title: "Video Name",
      dataIndex: "video_title",
      search: true,
    },
    {
      key: "image",
      title: "Image",
      dataIndex: "video_image_link",
      render: (text, row) => (
        <img src={row?.video_image_link} alt="img" style={{width:"80px",height:"80px"}} className="video-image" />
      ),
    },
    {
      key: "description",
      title: "vemio id",
      dataIndex: "video_player_id",
 
    },
    {
      key:"Loom Url",
      title:"Loom Url",
      dataIndex:"loom_url",
      render:(text , row) => row?.loom_url && <a className="btn btn-primary" href={row?.loom_url} target="_blank">Loom Url</a>
    },
    {
      key: "actions",
      title: "Actions",
      render: (text, row) => (
        <DropMenu child={"Actions"}>
          <div className="actions-btns">
           {spliitedUserPermission?.includes("15") &&  <div
              className="open-btn c-pointer text-primary"
              onClick={() => setOpenEditModal(row)}
            >
              <div className="btn btn-warning">Edit</div>
            </div>}
            {/* <div
            className="open-btn c-pointer text-primary"
            onClick={() => console.log(row.video_image_link)
            }
          >
            <div className="btn btn-primary">Watch</div>
          </div> */}
           {spliitedUserPermission?.includes("17") &&  <div
              className="open-btn c-pointer text-primary"
              onClick={() => setPdfOpen(row)}
            >
              <div className="btn btn-dark">PDF</div>
            </div>}
            {spliitedUserPermission?.includes("16") &&  <div
              className="open-btn c-pointer text-primary"
              onClick={() => setOpenDeleteModal(row)}
            >
              <div className="btn btn-danger">deLete</div>
            </div>}

         
            {/* <div className="rowDiv">
              <button
                className={`btn ${
                  !parseInt(row?.hidden) ? "btn-danger" : "btn-success"
                }`}
                onClick={async () => {
                  alert(row?.hidden)
                  try {
                    const response = await fetch(
                      "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/update_show_video.php",
                      {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                           
                          hidden: row?.hidden == "0" ? 1 : "0",
                          video_id: row?.video_id,
                        }),
                      }
                    );
                    const x = await response?.text();
                    getLecVideos();
                  } catch (err) {}
                }}
              >
                {parseInt(row?.hidden) ? "Show" : " Hide"}
              </button>
            </div> */}
          </div>
        </DropMenu>
      ),
    },
  ];

  return (
    <div className="videos">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Videos</h1>
        {spliitedUserPermission?.includes("14") &&<button
          className="btn btn-success"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Add Video
        </button>}
      </div>
      <CustomTable dataSource={videos} columns={columns} />
      <AddVideo
      setToast={setToast}
        getFunction={getLecVideos}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <EditVideo
      setToast={setToast}
        getFunction={getLecVideos}
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
      />
      <DeleteVideo
      setToast={setToast}
        getFunction={getLecVideos}
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
      />
      <ShowHideVideo
        openModal={openShowHideModal}
        setOpenModal={setOpenShowHideModal}
      />
      <PdfOpen
        spliitedUserPermission={spliitedUserPermission}
        getFunction={getLecVideos}
        openModal={pdfOpen}
        setOpenModal={setPdfOpen}
      />
       {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(false)}
          />
        )}
    </div>
  );
}

export default CategoryVideos;
